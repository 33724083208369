.menu-container {
    position: relative;

    .dropdown-menu {
        position: absolute;
        top: 42px;
        inset-inline-end: 10px;
        width: 100px;
        // height: 68px;
        border-radius: 7px;
        background: var(--white);
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
        z-index: 10;
        padding: 6px;

        .menu-item {
            display: flex;
            align-items: center;
            padding: 6px 12px;
            gap: 6px;
            cursor: pointer;

            .menu-icon {
                width: 16px;
                height: 16px;
            }

            span {
                font-size: var(--font-14);
                color: var(--primary-color);
                font-weight: var(--weight-400);
            }
        }
    }
}