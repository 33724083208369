.About-us {
  padding: 0px 6% 20px 6%;
  color: var(--text-color);
  font-size: var(--font-20);
  font-weight: var(--weight-400);
  line-height: 30px;
  text-align: justify;
  line-height: 1.5;
}

.custom-button {
  color: var(--gray-placeholder);
  border-radius: 20px;
  padding: 13px 23px 13px 23px;
  height: 40px;
  background-color: #fff;
  border: 1px solid #ddd;
  transition: background-color 0.3s, color 0.3s;
  font-size: var(--font-18);
  font-family: var(--primary-font);
  box-shadow: none !important;
}

.header-buttons {
  display: flex;
  gap: '14px';
}

.custom-button:hover {
  background-color: #00bfff !important;
}

.custom-button.active {
  background-color: #00bfff;
  color: #fff;
}

.header-buttons {
  gap: 4px;

}

.sectionDetails {
  padding: 60px 6%;
  

  .title {
    color: var(--primary-color);
    font-family: var(--primary-font);
    font-size: var(--font-25);
    font-weight: var(--weight-600);
    margin: 0 !important;
  }

  .content {
    font-size: var(--font-20);
    font-family: var(--primary-font);
    font-weight: var(--weight-400);
    color: var(--text-color);
    margin:  0 !important;

  }
}

@media (max-width: 1146px) {
  .header-buttons {
    display: flex;
    overflow-x: auto;
    gap: 4px;
    padding: 10px 0;
    white-space: nowrap;
    position: relative;
    height: 100px;
  }

  .ant-btn {
    min-width: 120px;
  }

  .header-buttons::-webkit-scrollbar {
    display: none;
  }

  .header-buttons {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}