.sticky-filter-buttons {
    display: flex;
    justify-content: center;
    position: sticky;
    top: -1px;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 10;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 23px 0px 23px 0px !important;
    border-top: 2px solid var(--sitting-header-bg);
    margin-top: -1px;
    margin-bottom: 20px;
}

.breadCrumbs {
    display: flex;
    justify-content: row;
    align-items: center;
    gap: 10px;

    .homeCrumbs {
        color: var(--blogs-breadCrumbs-color);
        font-family: var(--primary-font);
        font-size: var(--font-18);
        font-weight: var(--weight-400);
        cursor: pointer;
        white-space: nowrap;
    }

    .selectedFilter {
        color: var(--blogs-breadCrumbs-color);
        font-family: var(--primary-font);
        font-size: var(--font-18);
        font-weight: var(--weight-400);
        text-transform: capitalize;
        white-space: nowrap;

    }

    .selectedFilterTitle {
        color: var(--blogs-breadCrumbs-color);
        font-family: var(--primary-font);
        font-size: var(--font-18);
        font-weight: var(--weight-400);
        text-transform: capitalize;

    }

    .bracket {
        color: var(--black);
        font-family: var(--primary-font);
        font-size: var(--font-18);
        font-weight: var(--weight-400);
    }
}

@media (max-width:820px) {
    .sticky-filter-buttons {
        padding: 20px 0px 20px 0px !important;

    }
}