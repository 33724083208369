.partner-modal {
    .partner-modal-header {
        font-size: var(--font-30);
        font-family: var(--primary-font);
        color: var(--text-color);
        font-weight: var(--weight-700);
    }
    .ant-form-item-control-input-content div{
        margin-bottom: 0px !important;
    }

    .ant-modal-content {
        border-radius: 30px;
        padding: 60px 50px 60px 50px;

    }

    .partner-button {
        max-width: 260px;
        padding: 0.75rem;
        background-color: var(--primary-color);
        color: #fff;
        font-size: 1rem;
        font-weight: bold;
        border: none;
        border-radius: 25px;
        cursor: pointer;
        transition: background-color 0.2s;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        height: 49px;
        margin: 0 auto;

        &:disabled {
            background-color: #dcdcdc;
            cursor: not-allowed;
        }
    }

    .input-group {
        position: relative;
        margin-bottom: var(--font-20);
        text-align: start;

        .upload {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-top: 25px;
            flex-direction: column;
            .ant-upload-list-item-name{
                width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .upload-btn {
                background: #01BAFF0F;
                border-color: #01BAFF;
                border-radius: 30px;
                height: 45px;
                width: 100%;
                display: block;

                span {
                    color: var(--primary-color);
                }
            }

            .ant-upload {
                width: 100% !important;

            }

            .ant-upload-select {
                width: 100%;
            }
        }

        span {
            color: var(--text-color);
            font-weight: var(--weight-400);
            font-size: var(--font-14);
            font-family: var(--primary-font);
        }

        .InputBoxes {
            border-radius: 25px;
            height: 46px;
            margin-top: 2px;
        }

        .InputBoxesMessage {
            border-radius: 18px;
            margin-top: 2px;
            max-height: 100px;
        }


        .icon {
            position: relative;
            color: var(--border-signIn-Icon);
            font-size: 16px;
        }

        // Input {
        //     width: 100%;
        //     padding: 0.75rem 1rem 0.75rem 2.5rem;
        //     border: 1px solid $border-color;
        //     font-size: 0.9rem;
        //     outline: none;
        //     transition: border-color 0.2s;

        //     &:focus {
        //         border-color: $primary-color;
        //     }
        // }
    }

    .InputBoxes {
        border-radius: 25px;
        height: 46px;
        margin-top: 2px;
    }
}