.phone-input-container {
    display: flex;
    align-items: center;
    padding: 0 !important;
    direction: ltr !important;
  }
  
  .phone-input {
    border-radius: 30px !important; 
    width: 100% !important;
  }
  
 
 
  .phone-input-container input {
    padding-left: 70px !important;
color: var(--sign-in-gray);  
font-size: var(--font-14);
font-weight: var(--weight-400);
font-family: var(--primary-font);
}

  
  .phone-input-container button {
    border: none !important;
    background: none !important;
    cursor: pointer;
  }
  .country-button {
    border-radius: 30px 0 0 30px;
    width: 62px;
    display: flex;
    justify-content: center;
    background: transparent !important;
    transition: none; 
   
  }
  
 
  .react-tel-input .flag-dropdown .selected-flag:hover {
    background-color: transparent !important;
    box-shadow: none !important;
    cursor: pointer !important;
    
  }
  .react-tel-input .flag-dropdown .selected-flag {
    background-color: transparent !important;
    box-shadow: none !important;
    cursor: pointer !important;
    
  }
  

.react-tel-input .flag-dropdown .arrow {
    display: none !important;
}

.react-tel-input .flag-dropdown .selected-flag {
    position: relative; 
}

.react-tel-input .flag-dropdown .selected-flag::after {
    content: '';
    position: absolute;
    right: 3px;
    top: 50%;
    transform: translate(50%,-20%); 
    width: 12px; 
    height: 12px;
    background-image: url('../../assets/icons/arrowPhone.svg') !important; 
    background-size: contain;
    background-repeat: no-repeat;
}

.react-tel-input .flag-dropdown:hover .selected-flag::after {
    background-image: url('../../assets/icons/arrowPhone.svg') !important; 
}

