// Variables for colors
$primary-color: #01BAFF;



.login-container {
    background-color: var(--white);

    .login-box {
        background: var(--white);


        text-align: center;

        h2 {
            font-size: var(--font-30);
            font-weight: var(--weight-700);
            margin-bottom: 0.5rem;
            color: var(--sign-in-text);
            font-family: var(--primary-font);
        }

        .EnterEmail {
            font-size: var(--font-12);
            color: var(--text-color);
            margin-bottom: 1.5rem;
            font-weight: var(--weight-500);
            font-family: var(--primary-font);
        }

        .EnterEmailForgetPass {
            font-size: var(--font-12);
            color: var(--text-color);
            margin-bottom: 1.5rem;
            font-weight: var(--weight-500);
            font-family: var(--primary-font);
            max-width: 300px;
            margin: 0 auto;
        }

        form {
            padding: 0px 17% 0px 17%;

        }

        .social-media-group {
            padding: 0px 17% 0px 17%;

            p {
                margin: 0;
                padding-bottom: 20px;
                color: var(--primary-color);
                font-size: var(--font-12);
                font-family: var(--primary-font);
                font-weight: var(--weight-500);
            }

        }

        .input-group {
            position: relative;
            margin-bottom: var(--font-20);
            text-align: start;

            span {
                color: var(--text-color);
                font-weight: var(--weight-400);
                font-size: var(--font-14);
                font-family: var(--primary-font);
            }

            .InputBoxes {
                border-radius: 25px;
                height: 46px;
                margin-top: 2px;
            }


            .icon {
                position: relative;
                color: var(--border-signIn-Icon);
                font-size: 16px;
            }

            // Input {
            //     width: 100%;
            //     padding: 0.75rem 1rem 0.75rem 2.5rem;
            //     border: 1px solid $border-color;
            //     font-size: 0.9rem;
            //     outline: none;
            //     transition: border-color 0.2s;

            //     &:focus {
            //         border-color: $primary-color;
            //     }
            // }
        }

        .actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1.5rem;

            .rememberGroup {
                display: flex;
                align-items: center;
                gap: 5px;
            }

            label {
                font-size: 0.85rem;
                color: var(--sign-in-text);
            }

            .forgot-password {
                color: var(--primary-color);
                font-size: 0.85rem;
                text-decoration: none;
            }

            .rememberMe {
                color: var(--sign-in-gray);
                font-size: var(--font-12);
                font-family: var(--primary-font);
                font-weight: var(--weight-400);
                position: relative;
                top: 1px;
            }

        }

        .login-button {
            width: 100%;
            padding: 0.75rem;
            background-color: var(--primary-color);
            color: #fff;
            font-size: 1rem;
            font-weight: bold;
            border: none;
            border-radius: 25px;
            cursor: pointer;
            transition: background-color 0.2s;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;

            &:hover {
                background-color: darken($primary-color, 10%);
            }

            &:disabled {
                background-color: #dcdcdc;
                cursor: not-allowed;
            }
        }

        .divider {

            display: flex;
            align-items: center;
            color: var(--border-signIn-Icon);
            padding: 20px 17% 20px 17%;

            &::before,
            &::after {
                content: '';
                flex: 1;
                height: 1px;
                background-color: var(--border-signIn-Icon);
            }

            span {
                padding: 0px 16px 0px 16px;
                font-size: var(--font-12);
                font-weight: var(--weight-400);
                font-family: var(--primary-font);
                color: var(--sign-in-gray);
            }

        }

        .social-button {
            width: 100%;
            padding: 0.75rem;
            border: 1px solid var(--box-gray-modal);
            color: var(--sign-in-text);
            font-size: var(--font-14);
            font-weight: var(--weight-600);
            font-family: var(--primary-font);
            font-weight: bold;
            background-color: #fff;
            border-radius: 25px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: background-color 0.2s;
            margin-bottom: 0.75rem;

            .social-icon {
                margin-right: 0.5rem;
                font-size: 1.2rem;
            }
        }

        .google-button {
            border-color: var(--box-gray-modal);
            color: var(--sign-in-text);
        }

        .apple-button {
            border-color: var(--box-gray-modal);
            color: var(--sign-in-text);
        }

        .footer-text {
            font-size: var(--font-12);
            font-weight: var(--weight-500);
            font-family: var(--primary-font);
            color: var(--sign-in-gray);
            margin-top: 1.5rem;

            a {
                color: var(--primary-color);
                text-decoration: none;
            }
        }
    }
}


input:-webkit-autofill {
    background-color: transparent !important;
    box-shadow: 0 0 0px 1000px #FFF inset !important;
}


[dir="rtl"] .arrowSignIn {
    transform: scaleX(-1);
}

[dir="rtl"] .social-icon {
    margin-left: 0.5rem;

}

@media (max-width: 575px) {
    .login-container {
        .login-box {
            form {
                padding: 0px 10% 0px 10%;
            }

            .social-media-group {
                padding: 0px 10% 0px 10%;

            }

            .divider {
                padding: 20px 10% 20px 10%;

            }
        }

    }
}


.error {
    font-size: 14px;
    color: #d9534f;
    margin-bottom: 16px;
}