.shareModal {
  .custom-modal .ant-modal-content {
    border-radius: 50px !important;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    visibility: hidden;
    opacity: 0;
  }

  .modal-overlay.open {
    visibility: visible;
    opacity: 1;
    z-index: 999999;
  }

  .ant-modal-content {
    padding: 0 !important;
    width: 100%;
    height: 100%;
  }

  .ant-modal-body {
    padding: 0 !important;
  }

  .modal-content {
    background: white;
    border-radius: 14px;
    width: 90%;
    max-width: 531px;
    text-align: center;
    position: relative;
    transition: transform 0.3s ease;
    z-index: 99999;

  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--box-gray);
    padding: 35px 55px 19px 55px;

    .shareText {
      font-size: var(--font-24);
      font-weight: var(--weight-500);
      color: var(--black);
      font-family: var(--primary-font);
      margin: 0;


    }


  }

  .share-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 10px 55px 19px 55px;

    button {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: none;
      border: none;
      cursor: pointer;

      span {
        margin-top: 8px;
        font-size: var(--font-14);
        color: var(--black);
        font-weight: var(--weight-400);
        font-family: var(--primary-font);
      }
    }
  }

  .modal-footer {
    padding-top: 10px;
    font-size: 12px;
    color: gray;

    padding: 0px 55px 48px 55px;

    .s {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #eee;
      padding-top: 25px;
      flex-wrap: wrap;

    }

    .footer-text {
      font-size: var(--font-12);
      color: var(--gray-placeholder);
      font-weight: var(--weight-400);
      font-family: var(--primary-font);
      white-space: nowrap;
    }

    .copy-link-btn {
      background: none;
      border: none;
      color: var(--primary-color);
      font-size: var(--font-14);
      font-weight: var(--weight-400);
      font-family: var(--primary-font);
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 5px;
      white-space: nowrap;
    }
  }

  .close-btn {
    background: none;
    border: none;
    cursor: pointer;
  }

  @media (max-width: 600px) {
    .modal-header {
      padding: 35px 18px 19px 18px;

    }

    .share-buttons {
      padding: 10px 18px 19px 18px;

    }

    .modal-footer {
      padding: 0px 18px 48px 18px;

    }

  }
}