.BlogDetailsPage {

  .BlogImage {
    width: 100%;
    object-fit: cover;
    z-index: -1;
    border-radius: 13px;
  }

  [dir='rtl'] & {
    .Brackets {
      transform: scaleX(-1);
    }
  }

  .blogDetailsTitle {
    font-size: var(--font-35);
    font-family: var(--primary-font);
    font-weight: var(--weight-600);
    color: var(--text-color);
  }

  .blogDetailsContent {
    font-size: var(--font-20);
    font-weight: var(--weight-400);
    font-family: var(--primary-font);

    .ql-size-small {
      font-size: 0.75em;
    }

    .ql-size-large {
      font-size: 1.5em;
    }

    .ql-size-huge {
      font-size: 2.5em;
    }
  }

  .BlogDetailsNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
    position: sticky;
    top: 90px;
    z-index: 2;
    padding-left: 6%;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);


    .views {
      display: flex;
      align-items: center;
      gap: 8px;

      .viewsNumber {
        font-size: var(--font-18);
        font-weight: var(--weight-400);
        font-family: var(--primary-font);
        color: var(--blogs-breadCrumbs-color);
      }
    }

    .likedNum {
      font-size: var(--font-16);
      font-weight: var(--weight-400);
      font-family: var(--primary-font);
      color: var(--blogs-breadCrumbs-color);
    }

    .disLikedNum {
      font-size: var(--font-16);
      font-weight: var(--weight-400);
      font-family: var(--primary-font);
      color: var(--blogs-breadCrumbs-color);
    }

    .LikesLine {
      border: 1px solid var(--blogs-breadCrumbs-color);
      width: 1px
    }

  }


  .breadCrumbs {
    .selectedFilterTitle {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 1;
      max-width: 600px;
    }
  }
}

@media (max-width:820px) {
  .BlogDetailsPage {
    .BlogDetailsNav {
      top: 80px;
    }

    .blogDetailsTitle {
      font-size: var(--font-35) !important;

    }

    .blogDetailsContent {
      font-size: var(--font-16) !important;
    }
  }
}

@media (max-width:575px) {
  .BlogDetailsPage {
    .blogDetailsNavIcon {
      width: 16px;
    }

    .viewsNumber {
      font-size: var(--font-12) !important;
    }

    .blogDetailsContent {
      font-size: var(--font-12) !important;
    }

    .blogDetailsTitle {
      font-size: var(--font-18) !important;

    }

    .breadCrumbs {
      margin-top: 15px;
    }

    .selectedFilter {
      font-size: var(--font-10) !important;

    }

    .selectedFilterTitle {
      font-size: var(--font-10) !important;
    }

    .homeCrumbs {
      font-size: var(--font-10) !important;

    }
  }
}