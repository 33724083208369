.map-section-contactUs {
    width: 100%;
    margin-bottom: 40px;

    .map-title {
        color: var(--black);
        font-size: var(--font-30);
        font-weight: var(--weight-600);
        position: relative;
        padding-bottom: 10px;

        &::after {
            content: '';
            display: block;
            width: 77px;
            height: 3px;
            background-color: var(--primary-color);
            position: absolute;
            bottom: -5px;
        }
    }

    .map-address {
        color: var(--text-color-light);
        font-size: var(--font-18);
        font-weight: var(--weight-400);
        margin-top: 40px;
        margin-bottom: 30px;
    }

    .map-container {
        overflow: hidden;
        background-color: var(--text-color-light);
        height: 362px;
        max-width: 492px;
        border-radius: 17px;

        .map {
            height: 100%;
            width: 100%;
        }

        .loading-placeholder {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            background: #eaeaea;
            color: var(--primary-color);
        }
    }

    @media (max-width: 425px) {
        .map-container {
            height: 209px;
        }
    }
}