@import '../../styles/variabels.scss';

.launch-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding-top: 50px;
  width: 90%;
  margin: 0 auto;

  .left-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 35%;
    font-weight: var(--weight-700);

    .content {
      display: flex;
      flex-direction: column;
      gap: 50px;

      .cta {
        font-size: var(--font-45);
        color: var(--text-color);
      }


      .form-container {
        display: flex;
        flex-direction: column;
        gap: 23px;

        .subtext {
          font-size: var(--font-20);
          line-height: 23.44px;
          color: var(--text-color);
          margin: unset;
        }

        input[type="email"] {
          width: 100%;
          height: 62px;
          border-radius: 30px;
          border: 3px solid var(--box-gray);
          background: var(--white);
          padding-left: 36px;
          color: #000;
          font-size: var(--font-16);
          font-weight: var(--weight-400);
        }

        button {
          width: 100%;
          height: 64px;
          padding: 0 24px;
          border-radius: 35px;
          border: none;
          background: var(--primary-color);
          color: var(--white);
          text-align: center;
          font-size: var(--font-16);
          font-weight: var(--weight-700);
          transition: background-color 0.3s ease;

          &:hover {
            filter: brightness(90%);
          }
        }

      }


    }

    .socials {
      display: flex;
      flex-direction: column;
      align-items: center;

      .social-icons {
        display: flex;
        justify-content: left;
        gap: 24px;
        margin-top: 50px;

        a {
          img {
            width: 24px;
            height: 24px;
            transition: transform 0.3s ease;

            &:hover {
              transform: scale(1.3);
            }
          }
        }
      }

      .copyrights {
        color: var(--gray);
        font-size: var(--font-14);
        font-weight: var(--weight-400);
      }
    }
  }


  .right-section {
    height: 100%;
    width: 65%;
    align-content: center;

    .hero-image {
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block;
    }
  }

  // @media (max-width:1024px) {
  //   .left-section {
  //     width: 40%;
  //   }

  //   .right-section {
  //     width: 60%;
  //   }
  // }

  @media (max-width: 1023px) {
    .left-section {
      width: 100%;
    }

    .right-section {
      display: none;
    }

  }
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin: 10px 0;

  input[type="checkbox"] {
    margin-right: 10px;
  }

  label {
    font-size: 14px;
    line-height: 1.5;
  }
}

.checkbox-container {
  display: flex;
  align-items: flex-start;
  margin: 10px 0;

  // input[type="checkbox"] {
  //   margin-right: 10px;
  //   margin-top: 2px;
  // }

  label {
    font-size: 12px;
    line-height: 1.5;
  }
}


.error-message {
  color: red;
  font-size: 14px;
}

.success-message {
  color: green;
  font-size: 14px;
}