.blogs-filters {
    overflow: auto;
    padding: 0 6%;
    position: relative;

    .spin {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }
    .noBlog{
padding: 20px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
        .NoBlogText{
            color: var(--box-gray-modal);
            font-size: var(--font-25);
            text-align: center;
            font-weight: var(--weight-600);
            font-family: var(--primary-font);
        }
    }
  
    .filter-button {
        color: var(--gray-placeholder);
        border-radius: 30px;
        padding: 13px 23px;
        // height: 48px;
        background-color: var(--white);
        border: 1px solid #ddd;
        transition: background-color 0.3s, color 0.3s;
        font-size: var(--font-18);
        font-family: var(--primary-font);
        box-shadow: none !important;
        -webkit-tap-highlight-color: transparent;
        cursor: pointer;
        display: inline-block;
        text-align: center;
        white-space: nowrap;
        margin: 2px;
        margin-right: 5px;
        margin-left: 5px;
    }

    .title {
        color: var(--primary-color);
        font-family: var(--primary-font);
        font-size: var(--font-25);
        font-weight: var(--weight-600);
        padding-bottom: 31px;
        margin: 0;
        margin-top: 41px;
    }

    .filter-header-buttons {
        display: flex;
        gap: 14px;
        -webkit-tap-highlight-color: transparent;
    }


    .filter-button.active {
        background-color: #00bfff;
        color: #fff;
    }

    .sticky-filter-buttons::-webkit-scrollbar {
        display: none;
    }

    .breadCrumbs {

        display: flex;
        justify-content: row;
        align-items: center;
        gap: 10px;
        position: absolute;
        top: -17px;

        [dir='rtl'] & {
            .Brackets {
                transform: scaleX(-1);
            }
        }

        .homeCrumbs {
            color: var(--blogs-breadCrumbs-color);
            font-family: var(--primary-font);
            font-size: var(--font-18);
            font-weight: var(--weight-400);
            cursor: pointer;
        }

        .selectedFilter {
            color: var(--blogs-breadCrumbs-color);
            font-family: var(--primary-font);
            font-size: var(--font-18);
            font-weight: var(--weight-400);
            text-transform: capitalize;
        }

        .bracket {
            color: var(--black);
            font-family: var(--primary-font);
            font-size: var(--font-18);
            font-weight: var(--weight-400);

        }


    }

    .horizontal-scroll {
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .scrollItems {
        display: flex;
        justify-content: start;
        align-items: center;
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        background-color: #fff;
        z-index: 10;
        overflow-x: auto;
        overflow-y: hidden;

    }

    .scrollItems::-webkit-scrollbar {
        display: none;
    }

}
.spin {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100dvh;
}
.lastBlogs {
    padding-bottom: 77px;
}

@media (max-width:820px) {
    .blogs-filters {
        padding: 0px 3% 0px 3%;
    }

}

@media (max-width:820px) {
    .lastBlogs {
        padding-bottom: 30px;
    }

    .blogs-filters {
        .title {
            margin-top: 29px;
            padding-bottom: 19px;

        }

        .filter-button {
            font-size: var(--font-14);
        }
    }

}

.blogs-categories {
    padding: 0;
}