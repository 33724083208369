.contactUsPage {
    margin-bottom: 250px;

    .Contact-us {
        padding: 0px 6%;
        color: var(--text-color);
        font-size: var(--font-20);
        font-weight: var(--weight-400);
        line-height: 30px;

        .contact-section {
            margin-top: 65px;
        }

        .contactUs-title {
            padding: 0;
            margin: 0;
            padding-top: 7px;
            color: var(--edit-profile-text);
            font-size: var(--font-18);
            font-weight: var(--weight-800);
            font-family: var(--primary-font);
        }

        .input-group {
            position: relative;
            margin-bottom: var(--font-20);
            text-align: start;

            span {
                color: var(--text-color);
                font-weight: var(--weight-400);
                font-size: var(--font-14);
                font-family: var(--primary-font);
            }

            .InputBoxes {
                border-radius: 25px;
                height: 46px;
                margin-top: 2px;
            }


            .icon {
                position: relative;


                color: var(--border-signIn-Icon);
                font-size: 16px;
            }

            // Input {
            //     width: 100%;
            //     padding: 0.75rem 1rem 0.75rem 2.5rem;
            //     border: 1px solid $border-color;
            //     font-size: 0.9rem;
            //     outline: none;
            //     transition: border-color 0.2s;

            //     &:focus {
            //         border-color: $primary-color;
            //     }
            // }
        }

        .w {
            display: flex;
            justify-content: center;

            .login-button {
                width: 277px;
                padding: 0.75rem;
                background-color: var(--primary-color);
                color: #fff;
                font-size: 1rem;
                font-weight: bold;
                border: none;
                border-radius: 25px;
                cursor: pointer;
                transition: background-color 0.2s;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                height: 49px;
                margin-top: 15px;


            }
        }

        .contactUs-headers {
            font-size: var(--font-18);
            font-weight: var(--weight-700);
            font-family: var(--primary-font);
            color: var(--primary-color);
            margin: 0;
            padding-bottom: 17px;
        }

        .contact-info {
            p {
                font-size: var(--font-14);
                font-weight: var(--weight-400);
                font-family: var(--primary-font);
                color: var(--text-color);
                margin: 0;
                padding-bottom: 5px;
                display: flex;
                align-items: center;
                gap: 14px;

            }
        }

    }

}



@media (max-width: 1146px) {
    .header-buttons {
        display: flex;
        overflow-x: auto;
        gap: 4px;
        padding: 10px 0;
        white-space: nowrap;
        position: relative;
        height: 100px;
    }

    .ant-btn {
        min-width: 120px;
    }

    .header-buttons::-webkit-scrollbar {
        display: none;
    }

    .header-buttons {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
}