.termsAndCondition {
    .custom-button {
        color: var(--gray-placeholder);
        border-radius: 30px;
        padding: 13px 23px;
        height: 48px;
        background-color: #fff;
        border: 1px solid #ddd;
        transition: background-color 0.3s, color 0.3s;
        font-size: var(--font-18);
        font-family: var(--primary-font);
        box-shadow: none !important;
        -webkit-tap-highlight-color: transparent;
        cursor: pointer;
        display: inline-block;
        text-align: center;
        white-space: nowrap;
        margin-right: 10px;
    }

    .header-buttons {
        display: flex;
        gap: 14px;
        -webkit-tap-highlight-color: transparent;
    }

    .custom-button:hover {
        background-color: #00bfff !important;
        color: #fff !important;
    }

    .custom-button.active {
        background-color: #00bfff;
        color: #fff;
    }

    .sectionDetails {
        padding: 0px 6% !important;
        background-color: #fff;

        .title {
            color: var(--primary-color);
            font-family: var(--primary-font);
            font-size: var(--font-25);
            font-weight: var(--weight-600);
            padding-bottom: 15px;
        }

        .content {
            font-size: var(--font-20);
            font-family: var(--primary-font);
            font-weight: var(--weight-400);
            color: var(--text-color);
            position: relative;
            text-align: justify;
            line-height: 1.5;
        }
    }

    .sticky-buttons {
        display: flex;
        justify-content: start;
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        background-color: #fff;
        z-index: 10;
        padding: 10px;
        padding: 40px 6% 100px 6%;
        overflow-x: auto;
        overflow-y: hidden;
    }

    .sticky-buttons::-webkit-scrollbar {
        display: none;
    }
}

@media (max-width:820px) {
    .sticky-buttons {
        padding: 30px 3% 100px 3%;
    }

    .termsAndCondition {
        .sectionDetails {
            padding: 0px 3% !important;
        }
    }

}