.group{
    display: flex;
    flex-direction: column;
    padding:24px 6% 0px 6%;
    p{
        margin: 0 !important;
    }
    .h1{
        color:var(--primary-color);
        font-size: var(--font-25);
        font-weight: var(--weight-600);
        font-family: var(--primary-font);
        line-height: 29.3px;
    }
    .title{
        color:var(--text-color-light);
        font-size: var(--font-20);
        font-weight: var(--weight-400);
        font-family: var(--primary-font);
        line-height: 23.4px;
    }
}
