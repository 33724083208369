.blogCard {
    height: 425px;
    border-radius: 16px;
    box-shadow: 0px 0px 10px 0px #00000040;
    cursor: pointer;

    .blogImg {
        object-fit: cover;
        max-height: 252px;
        min-height: 252px;
        height: 252px;
        aspect-ratio: 3/3;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px
    }

    .love {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 4;
        display: flex;
        gap: 6px;

        .lf-btn {
            cursor: pointer;
            transition: transform 0.3s ease, filter 0.3s ease;

            &.empty {
                filter: none;
            }

            &:active {
                transform: scale(1.2);
            }

            &.pulse {
                &:active {
                    animation: pulse-animation 0.3s ease-in-out;
                }
            }
        }
    }

    .blogViews {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .viewTitle {
            color: var(--primary-color);
            font-size: var(--font-12);
            font-family: var(--primary-font);
            font-weight: var(--weight-500);
            margin: 0;
            padding-bottom: 9px;
        }

    }

    .read-more {
        color: var(--primary-color);
        font-size: var(--font-13);
        font-family: var(--primary-font);
        font-weight: var(--weight-400);
        text-decoration: underline;
        cursor: pointer;
        position: absolute;
        bottom: 0;
        right: 0;
        padding-right: 17px;
    }

    .blog-title {
        font-size: var(--font-18);
        color: var(--black);
        font-weight: var(--weight-500);
        line-height: 21px;
        font-family: var(--primary-font);
        margin: 0;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 2;
        max-height: 40px;
    }

    .blog-description {
        font-size: var(--font-14);
        color: var(--text-color);
        font-weight: var(--weight-400);
        line-height: 17px;
        margin: 0;
        font-family: var(--primary-font);
        padding-top: 5px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 2;
        padding-top: 5px;
        word-break: break-all;

    }

}

@media (max-width:675px) {
    .blogCard {
        box-shadow: none;

        .blog-description {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            -webkit-line-clamp: 3;
            padding-top: 1px;

        }

        .blog-title {
            -webkit-line-clamp: 1;
        }
    }

}

@media (max-width:575px) {
    .blogCard {
        height: 260px;
        box-shadow: 0px 0px 10px 0px #00000040;

        .blogImg {
            max-height: 124px;
            min-height: 124px;
            height: 124px;

        }

        .blog-description {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            -webkit-line-clamp: 2;
        }

        .blogViews {
            .viewTitle {
                padding-bottom: 5px;
            }
        }
    }
}

@media (max-width:326px) {
    .blogCard {
        height: 280px;
    }
}