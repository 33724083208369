.report-modal {
    width: 394px !important;
    height: 454px !important;

    .ant-modal-content {
        border-radius: 20px;
        padding: 16px;

        @media (min-width: 768px) {
            padding: 25px;
        }
    }

    .ant-modal-title {
        color: var(--black);
        font-size: var(--font-20);
        font-weight: var(--weight-700);
        line-height: 30px;
    }

    .modal-description {
        width: 100%;
        color: var(--text-color);
        font-size: var(--font-14);
        font-weight: var(--weight-400);
        line-height: 24.7px;
        margin-bottom: 24px;
    }

    .field-label {
        color: var(--text-color);
        font-size: var(--font-14);
        font-weight: var(--weight-400);
        line-height: 160%;
    }

    .ant-form-item {
        margin-top: 4px;
        margin-bottom: 12px;
        height: auto;

        .ant-select {
            height: 46px;
        }

        .ant-select-selector,
        textarea,
        .ant-input-affix-wrapper {
            border-radius: 25px;
            padding: 12px;
            font-size: var(--font-14);
            border: 1px solid var(--box-gray);
            box-shadow: 0px 1px 2px 0px rgba(242, 242, 242, 0.24);
            height: 46px;
            align-self: stretch;
        }

        input {
            display: flex;
            align-items: center;
        }

        textarea {
            height: 128px;
            resize: none;
            font-size: var(--font-14);
            font-weight: var(--weight-400);
            color: var(--text-color);
        }

        textarea::placeholder {
            color: var(--gray-placeholder);
            font-size: var(--font-14);
            font-weight: var(--weight-400);
        }

        textarea:focus {
            border-color: var(--primary-color);
            outline: none;
        }
    }

    .icon-text-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .select-icon {
            margin-inline-end: 10px;
        }

        span {
            color: var(--gray-placeholder);
            font-size: var(--font-14);
            font-weight: var(--weight-400);
        }
    }

    .submit-btn-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }

    .submit-btn {
        background-color: var(--primary-color);
        border-radius: 30px;
        font-size: var(--font-16);
        font-weight: var(--weight-500);
        width: 171px;
        height: 44px;
        padding: 1px 26px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media (max-width: 768px) {
        .ant-modal-content {
            width: 100%;
            height: 100%;
            padding: 20px;
        }

        .ant-form-item {

            input,
            textarea,
            .ant-select-selector {
                font-size: var(--font-14);
            }
        }

        .submit-btn {
            font-size: var(--font-14);
            height: 48px;
        }
    }
}