@import "./styles/variabels.scss";

body {
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  /* max-width: var(--max-width); */
  overflow-x: hidden;
  background: var(--white);

}

.root_element {
  max-width: var(--max-width);
  margin: 0 auto;
  width: 100%;
  
  /* overflow-x: hidden; */
}

.container-width {
  margin: 0 auto;
  max-width: var(--max-width);
}

* {
  box-sizing: border-box;
  
}

html,
body {
  height: 100%;
  box-sizing: border-box;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

a {
  color: unset;
  text-decoration: none;
}

h1 {
  font-size: unset;
  margin: unset;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  height: 50vh;
}