.partners-section {
    padding: 0 6% 120px 6%;
    margin-top: 45px;

    .partners-card {
        background-color: var(--white);
        border-bottom: 1px solid #DADADA;

        .partner-image {
            max-width: 100%;
            display: flex;
            justify-content: center;
            align-items: start;
            width: 100%;
            height: 100%;
        }
    }

    .partners-card:not(:first-child) {
        margin-top: 24px;
    }

    .partners-body-section {
        box-shadow: 0px 0px 16px 0px #0000000F !important;
        border-radius: 13px !important;
        padding: 29px 50px 50px 50px;
        background-color: var(--white);
    }


    .partner-Card-Desc {
        display: flex;
        flex-direction: column;
        gap: 5px;
        transition: all 0.5s;
        padding-bottom: 16px;

        .show-more-btn {

            color: var(--primary-color);
            cursor: pointer;
            -webkit-tap-highlight-color: transparent;
            display: flex;
            align-items: center;
            justify-content: end;
            font-size: var(--font-12);
            font-weight: var(--weight-400);
            padding: 0 !important;

        }

        p {
            margin: 0;
        }

        .partner-name {
            font-size: var(--font-16);
            font-weight: var(--weight-500);
            font-family: var(--primary-font);
            color: var(--primary-color)
        }

        .partner-type {
            font-size: var(--font-14);
            font-weight: var(--weight-400);
            font-family: var(--primary-font);
            color: var(--text-color-light)
        }

        .partner-desc {
            font-size: var(--font-16);
            font-weight: var(--weight-400);
            font-family: var(--primary-font);
            color: var(--black);
            text-align: justify;
            transition: all 0.5s;
        }
    }


}

@media (max-width: 768px) {
    .partners-section {
        padding: 0 3% 120px 3%;


        .partners-body-section {
            padding: 34px 15px 22px 15px !important;
        }
    }

}

.contact-us-home-section {
    padding: 63px 108px 63px 108px;
    border-radius: 10px;
    margin: 20px 0 63px 0px;
    background: rgba(1, 186, 255, 0.03);




    .text-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0px;

        .section-content {
            display: flex;
            flex-direction: column;


            .partnerWith {
                color: var(--primary-color);
                font-size: var(--font-25);
                font-weight: var(--weight-600);
                font-family: var(--primary-font);
                line-height: 29.3px;
                margin: 0;
            }

            .fillOutText {
                color: var(--black);
                font-size: var(--font-20);
                font-weight: var(--weight-400);
                font-family: var(--primary-font);
            }
        }

        h2 {
            color: var(--black);
            font-size: var(--font-30);
            font-weight: var(--weight-700);
            font-family: var(--primary-font);
        }

        .request-button {
            width: 260px;
            height: 54px;
            padding: 14px 20px;
            justify-content: center;
            align-items: center;
            gap: 18px;
            border-radius: 37px;
            background-color: var(--primary-color);

            span {
                color: var(--white);
                text-align: center;
                font-size: var(--font-18);
                font-weight: var(--weight-600);
            }

            .arrow {
                font-size: var(--font-18);
                margin-inline-start: 5px;
            }
        }
    }


    @media (max-width:580px) {
        padding: 40px 20px 40px 15px;
        margin: 0px 0 !important;

        .text-container {
            flex-direction: column;
            margin-top: 0px;
            gap: 10px;
            margin: 0 !important;
            align-items: start;

            h2 {
                display: flex;
                justify-content: start !important;
                margin: 0 0 10px 0;

            }

            .request-button {
                width: 173px;
                height: 36px;
                margin-left: auto;
                margin-right: auto;
            }
        }

    }

}